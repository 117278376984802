import type { ReactNode } from 'react';
import { createContext, useState } from 'react';

export type TableProviderProps = {
  children: ReactNode;
};

export type TableContextType = {
  state: {
    count: number;
    selectAllPages: boolean;
    selectedRows: string[];
  };
  actions: {
    clearSelection: () => void;
    setCount: (count: number) => void;
    setSelectAllPages: (state: boolean) => void;
    setSelection: (selection: string[]) => void;
  };
};

export const TableContext = createContext({} as TableContextType);

const TableProvider = ({ children }: TableProviderProps) => {
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [count, setCount] = useState(0);
  const [selectAllPages, setSelectAllPages] = useState(false);

  const actions = {
    clearSelection: () => {
      setSelectedRows([]);
      setCount(0);
    },
    setCount,
    setSelectAllPages,
    setSelection: (rows: string[]) => {
      setSelectedRows(rows);
      setCount(rows.length);
    }
  };

  const state = {
    count,
    selectAllPages,
    selectedRows
  };

  return (
    <TableContext.Provider value={{ state, actions }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
