import { useEffect } from 'react';

export const useCompanyChange = (callback?: () => void) => {
  const eventName = 'onCompanyChanged';

  const subscribe = (listener: () => void) => {
    document.addEventListener(eventName, listener);
  };

  const unsubscribe = (listener: () => void) => {
    document.removeEventListener(eventName, listener);
  };

  const emitCompanyChangedEvent = () => {
    const event = new CustomEvent(eventName);
    document.dispatchEvent(event);
  };

  useEffect(() => {
    if (!callback) return;

    subscribe(callback);

    return () => unsubscribe(callback);
  }, []);

  return {
    subscribe,
    unsubscribe,
    emitCompanyChangedEvent
  };
};

export default useCompanyChange;
