import { components } from 'react-select';

import OptionContent from './OptionContent';

const MultiValueContainer: typeof components.MultiValueContainer = ({
  children,
  ...props
}) => {
  const { data } = props;
  const { isMulti } = props.selectProps;

  return (
    <components.MultiValueContainer {...props}>
      {isMulti && data.color && <OptionContent option={data} type="control" />}
      {children}
    </components.MultiValueContainer>
  );
};

export default MultiValueContainer;
