import server from '../server';

const companiesService = {
  selectCompany: async (selectedCompanyId: number) => {
    return await server.patch({
      data: { selectedCompanyId },
      path: 'current_company'
    });
  }
};

export default companiesService;
