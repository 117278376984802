import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Svg from '@/components/Svg';

type ToastProps = {
  message: ReactNode;
  fullSize?: boolean;
};

const toastClasses =
  'flex py-1 w-max gap-3 rounded items-center border pl-2 pr-3 max-w-lg';

export const ErrorToast = ({ message, fullSize }: ToastProps) => (
  <div
    className={twMerge(
      toastClasses,
      'border-toasts-errorBorder bg-toasts-errorBackground text-toasts-errorText',
      fullSize && 'h-full w-full max-w-full py-4'
    )}
  >
    <div>
      <Svg icon="cancel" />
    </div>
    <div className="leading-relaxed">{message}</div>
  </div>
);

export const InfoToast = ({ message, fullSize }: ToastProps) => (
  <div
    className={twMerge(
      toastClasses,
      'border-toasts-infoBorder bg-toasts-infoBackground text-toasts-infoText',
      fullSize && 'h-full w-full max-w-full py-4'
    )}
  >
    <div>
      <Svg icon="info" stroke="#6C757D" />
    </div>
    <div className="leading-relaxed">{message}</div>
  </div>
);

export const LoadingToast = ({ message, fullSize }: ToastProps) => (
  <div
    className={twMerge(
      toastClasses,
      'border-toasts-loadingBorder bg-toasts-loadingBackground text-toasts-loadingText',
      fullSize && 'h-full w-full max-w-full py-4'
    )}
  >
    <div className="animate-spin">
      <Svg icon="loading" />
    </div>
    <div className="leading-relaxed">{message}</div>
  </div>
);

export const SuccessToast = ({ message, fullSize }: ToastProps) => (
  <div
    className={twMerge(
      toastClasses,
      'border-toasts-successBorder bg-toasts-successBackground text-toasts-successText',
      fullSize && 'h-full w-full max-w-full py-4'
    )}
  >
    <div>
      <Svg icon="tick" stroke="#345F1B" />
    </div>
    <div className="leading-relaxed">{message}</div>
  </div>
);

export const WarningToast = ({ message, fullSize }: ToastProps) => (
  <div
    className={twMerge(
      toastClasses,
      'border-toasts-warningBorder bg-toasts-warningBackground align-bottom text-toasts-warningText',
      fullSize && 'h-full w-full max-w-full py-4'
    )}
  >
    <div>
      <Svg icon="warning" />
    </div>
    <div className="leading-relaxed">{message}</div>
  </div>
);
