import { Collapse } from 'react-collapse';

import type { CategoryListProps } from '../nav.types';
import { isCategory } from '../nav.utils';
import CategoryItem from './CategoryItem';

const CategoryList = ({
  collapsed,
  depth,
  items,
  Category
}: CategoryListProps) => (
  <Collapse isOpened={!collapsed}>
    <ul className="flex flex-col">
      {items.map(item =>
        isCategory(item) ? (
          <Category
            depth={depth + 1}
            items={item.items}
            key={item.key}
            tName={item.key}
          />
        ) : (
          <CategoryItem
            depth={depth + 1}
            key={item.key}
            path={item.path}
            subpaths={item.subpaths}
            tName={item.key}
          />
        )
      )}
    </ul>
  </Collapse>
);

export default CategoryList;
