import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

import Link from '@/components/Link';
import Svg from '@/components/Svg';

import type { ButtonProps } from './button.types';

const Button = ({
  big,
  children,
  className,
  danger,
  disabled,
  link,
  loading,
  onClick,
  outlined,
  plusButton,
  redirectTo,
  relative,
  tickButton,
  type = 'button',
  value
}: ButtonProps) => {
  const classNames = getClassNames(
    big,
    children,
    className,
    danger,
    disabled,
    link,
    loading,
    outlined,
    plusButton
  );

  const getIconName = () => {
    switch (true) {
      case plusButton:
        return 'plus';
      case tickButton:
        return 'tick';
      default:
        throw new Error('Wrong button props');
    }
  };

  return redirectTo ? (
    <Link className={classNames} redirectTo={redirectTo} relative={relative}>
      {children}
    </Link>
  ) : (
    <button
      className={classNames}
      disabled={disabled || loading}
      onClick={onClick}
      type={type}
      value={value}
    >
      {!loading &&
        (plusButton || tickButton ? (
          <Svg icon={getIconName()} stroke="#FFF" />
        ) : (
          <div>{children}</div>
        ))}

      {loading && (
        <div className="no-wrap flex animate-spin content-center items-center justify-center">
          <Svg icon="loading" stroke={outlined ? '#727CF5' : `#FFF`} />
        </div>
      )}
    </button>
  );
};

export default Button;

const getClassNames = (
  big?: boolean,
  children?: ReactNode,
  className?: string,
  danger?: boolean,
  disabled?: boolean,
  link?: boolean,
  loading?: boolean,
  outlined?: boolean,
  plusButton?: boolean
) => {
  let classNames =
    'rounded border-solid font-bold transition-colors disabled:cursor-default w-max ';

  if (outlined && !disabled && !danger) {
    classNames += `border-buttons-default bg-white text-buttons-default ${
      !loading
        ? ' hover:border-accent hover:bg-accent hover:text-white active:bg-buttons-primaryButtonActive '
        : ''
    }  `;
  }
  if (outlined && disabled && !danger) {
    classNames +=
      'border-buttons-disabledTextButton text-buttons-disabledTextButton ';
  }
  if (outlined && !disabled && danger) {
    classNames +=
      'border-buttons-danger text-buttons-danger active:bg-buttons-dangerOutlinedActive hover:bg-buttons-danger hover:text-white ';
  }
  if (outlined && disabled && danger) {
    classNames += 'border-buttons-dangerDisabled text-buttons-dangerDisabled ';
  }
  if (!outlined && danger && !link) {
    classNames +=
      'bg-buttons-danger text-white hover:bg-buttons-dangerHovered active:bg-buttons-dangerActive ';
  }
  if (!outlined && link && !danger && !disabled) {
    classNames +=
      'py-2 px-0 bg-transparent text-accent hover:text-buttons-hoverButton ';
  }
  if (!outlined && link && danger && !disabled) {
    classNames +=
      'py-2 px-0 bg-transparent text-buttons-danger hover:text-buttons-hoverDangerLink ';
  }
  if (!outlined && link && !danger && disabled) {
    classNames += 'py-2 px-0 bg-transparent text-buttons-disabledTextButton ';
  }
  if (!outlined && !danger && !link) {
    classNames += `bg-buttons-default text-white hover:bg-buttons-hoverButton active:bg-buttons-primaryButtonActive ${
      !loading
        ? ''
        : 'active:bg-buttons-hoverButton hover:bg-buttons-hoverButton '
    }
    ${
      disabled
        ? 'opacity-60 active:bg-buttons-default hover:bg-buttons-default'
        : ''
    }
    `;
  }

  if (big && !plusButton) {
    classNames += 'text-xl h-11.5 leading-7.5 ';
  }

  if (!big) {
    classNames += 'text-sm h-9.5 leading-5.5 ';
  }

  if (children && !link) {
    classNames += 'px-6 py-2 ';
  }

  if (!children && !big) {
    classNames +=
      'p-2 w-9.5 h-9.5 flex no-wrap content-center items-center justify-center ';
  }

  if (!children && big) {
    classNames +=
      'p-2 w-11.5 h-11.5 flex no-wrap content-center items-center justify-center ';
  }

  if (outlined) {
    classNames += 'border ';
  }

  if (outlined && loading) {
    classNames += 'active:bg-white hover:bg-white ';
  }

  if (link && !disabled) {
    classNames += 'cursor-pointer ';
  }

  if (link && disabled) {
    classNames += 'cursor-default ';
  }

  if (className) {
    classNames = twMerge(classNames, className);
  }

  return twMerge(classNames);
};
