import SimpleBar from 'simplebar-react';

import type { NavListProps } from '../nav.types';
import Category from './Category';

const NavList = ({ navItems }: NavListProps) => (
  <SimpleBar className="pb-6" style={{ maxHeight: 'calc(100vh - 55px)' }}>
    <nav>
      <ul className="flex flex-col">
        {navItems.map(({ items, key }) => (
          <Category items={items} key={key} tName={key} />
        ))}
      </ul>
    </nav>
  </SimpleBar>
);

export default NavList;
