import { twMerge } from 'tailwind-merge';

import { useFeatureFlags } from '@/hooks';

const getEnvironmentInfo = (hostname: string) => {
  switch (hostname) {
    case 'localhost':
      return { bg: 'bg-[green]', label: 'Local' };
    case 'dash.splashbrandsdev.com':
      return { bg: 'bg-[green]', label: 'Dev' };
    case 'app.splashbrandsdev.com':
      return { bg: 'bg-[green]', label: 'Dev' };
    case 'app.splashbrandstest.com':
      return { bg: 'bg-[orange]', label: 'Stage' };
    case 'app.splashbrands.com':
      return { bg: 'bg-[red]', label: 'Prod' };
    default:
      return { bg: null, label: null };
  }
};

const DeveloperMode = () => {
  const { features } = useFeatureFlags();
  const { bg, label } = getEnvironmentInfo(window.location.hostname);

  return (
    features?.developerMode &&
    bg &&
    label && (
      <>
        <div
          className={twMerge('fixed left-0 top-0 z-50 h-0.5 w-screen', bg)}
        />
        <div
          className={twMerge('fixed left-0 top-0 z-50 h-screen w-0.5', bg)}
        />
        <div
          className={twMerge(
            'fixed left-[-47px] top-[-25px] z-50 rotate-[-45deg] p-[40px_40px_4px_40px] text-center font-bold text-white',
            bg
          )}
        >
          {label}
        </div>
      </>
    )
  );
};

export default DeveloperMode;
