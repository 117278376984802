import type { MenuButton } from '../header.types';

const MenuButton = ({ toggleMenu }: MenuButton) => {
  return (
    <button
      className="flex flex-col items-end justify-center gap-[3.5px]"
      onClick={toggleMenu}
    >
      <div className="h-0.5 w-5.5 rounded bg-quaternary"></div>
      <div className="h-0.5 w-3 rounded bg-quaternary"></div>
      <div className="h-0.5 w-4 rounded bg-quaternary"></div>
      <div className="h-0.5 w-5 rounded bg-quaternary"></div>
    </button>
  );
};

export default MenuButton;
