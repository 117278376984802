import { redirectToSignIn } from '@/utils/redirectToSignIn';

import server from '../server';
import type { UserData } from './types';

const authService = {
  getUser: async (): Promise<UserData> => server.get({ path: 'user' }),

  logout: async () => {
    await server.get({ path: 'users/sign_out' });
    redirectToSignIn();
  }
};

export default authService;
