import { useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useSearchParams } from 'react-router-dom';

import { useAuth } from '@/hooks';
import { useCompanyChange } from '@/hooks/useCompanyChange';
import { TableContext } from '@/providers';
import companiesService from '@/services/companies';

import Select from '../Select';
import type { CompanySelectProps } from './types';

const CompanySelect = ({
  accesses = [],
  selectedCompany
}: CompanySelectProps) => {
  const { pathname } = useLocation();
  const { emitCompanyChangedEvent } = useCompanyChange();
  const [, setSearchParams] = useSearchParams();
  const { mutate: refreshUser } = useAuth();
  const {
    actions: { clearSelection }
  } = useContext(TableContext);

  const { control, watch } = useForm({
    mode: 'onChange',
    defaultValues: {
      company: { value: selectedCompany?.id, label: selectedCompany?.name }
    }
  });

  const currentAccessCompanySelector = useMemo(
    () =>
      accesses.find(
        ({ path, subpaths }) =>
          pathname.includes(path) ||
          subpaths?.some(subpath => pathname.includes(subpath))
      )?.companySelector,
    [accesses, pathname]
  );

  useEffect(() => {
    const onSelectCompany = async (companyId?: number) => {
      if (!companyId) return;

      clearSelection();
      setSearchParams(new URLSearchParams(), { replace: true });
      await companiesService.selectCompany(companyId);
      emitCompanyChangedEvent();
      refreshUser();
    };

    const subscription = watch(values => {
      onSelectCompany(values.company?.value);
    });

    return () => subscription.unsubscribe();
  }, [emitCompanyChangedEvent, watch]);

  const visible = () => {
    if (pathname.includes('/new') || pathname.includes('/duplicate')) {
      return currentAccessCompanySelector?.new;
    }

    if (pathname.includes('/edit') || pathname.includes('/show')) {
      return currentAccessCompanySelector?.edit;
    }

    return currentAccessCompanySelector?.index;
  };

  const disabled = () => {
    if (pathname.includes('/new') || pathname.includes('/duplicate')) {
      return currentAccessCompanySelector?.new?.disabled;
    }

    if (pathname.includes('/edit') || pathname.includes('/show')) {
      return currentAccessCompanySelector?.edit?.disabled;
    }

    return currentAccessCompanySelector?.index?.disabled;
  };

  return visible() ? (
    <Select
      className="w-55"
      clearable={false}
      control={control}
      disabled={disabled()}
      mapper={({ id, name }) => ({ label: name, value: id })}
      mapperValueAccessor="data"
      menuListClassName="max-h-[40rem]"
      name="company"
      optionsUrl="companies"
      searchParamName="filters[phrase]"
    />
  ) : (
    <div />
  );
};

export default CompanySelect;
