import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

import Fallback from './components/Fallback';
import type { ErrorBoundaryProps } from './errorBoundary.types';
import { logError } from './utils/logError';

const ErrorBoundary = ({ children }: ErrorBoundaryProps) => (
  <ReactErrorBoundary FallbackComponent={Fallback} onError={logError}>
    {children}
  </ReactErrorBoundary>
);

export default ErrorBoundary;
