import { FEATURE_FLAGS } from '@/environment';

import type { FeatureFlagData, FetchedFeatureFlagData } from './types';

export const mapFeatureFlags = (
  { features }: FetchedFeatureFlagData,
  userId?: number
) =>
  Object.entries(FEATURE_FLAGS).reduce(
    (res: Record<string, boolean>, [key, value]) => {
      const feature = features.find(feature => feature.key === value);

      if (feature?.state === 'conditional') {
        const actorsGate = feature.gates.find(gate => gate.key === 'actors');
        const actors = actorsGate?.value ?? [];
        res[key] = actors.some(actor => actor === `User;${userId}`);
      } else {
        res[key] = feature?.state === 'on';
      }

      return res;
    },
    {}
  ) as FeatureFlagData;
