import { captureException } from '@sentry/react';
import type { ErrorInfo } from 'react';

import { SENTRY_ENABLED } from '@/environment';

export const logError = (error: Error, info: ErrorInfo) => {
  if (SENTRY_ENABLED) {
    captureException({ info, error });
  }
};
