import { twMerge } from 'tailwind-merge';

import { SkeletonProps } from './types';

const Skeleton = ({ className, component }: SkeletonProps) => (
  <div className={twMerge('flex animate-pulse', className)}>
    <div className="flex-1">
      <div
        className={twMerge(
          'h-4 rounded bg-[#EBEBEB]',
          (component === 'input' || component === 'select') && 'h-8',
          component === 'toggle' && 'h-6',
          component === 'textarea' && 'h-24'
        )}
      />
    </div>
  </div>
);

export default Skeleton;
