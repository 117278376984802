import { useState } from 'react';

import { t } from '@/translations/i18n';

import type { CategoryProps } from '../nav.types';
import CategoryHeader from './CategoryHeader';
import CategoryList from './CategoryList';

const Category = ({ depth = 0, items, tName }: CategoryProps) => {
  const [collapsed, setCollapsed] = useState(depth === 1);

  const toggleCollapsed = () => {
    setCollapsed(isCollapsed => !isCollapsed);
  };

  return (
    <li>
      <CategoryHeader
        collapsed={collapsed}
        depth={depth}
        name={t(`nav.${tName}`)}
        toggleCollapsed={toggleCollapsed}
      />
      <CategoryList
        Category={Category}
        collapsed={collapsed}
        depth={depth}
        items={items}
      />
    </li>
  );
};

export default Category;
