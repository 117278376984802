import { components } from 'react-select';

import type { OptionType } from '../types';
import OptionContent from './OptionContent';

const Control: typeof components.Control = ({ children, ...props }) => {
  const { getValue, isMulti } = props;

  const [value] = getValue();
  const option = value as OptionType;

  return (
    <components.Control {...props}>
      {!isMulti && <OptionContent option={option} type="control" />}
      {children}
    </components.Control>
  );
};

export default Control;
