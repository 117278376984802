import 'simplebar-react/dist/simplebar.min.css';

import { toast as reactToastify, ToastOptions } from 'react-toastify';

import {
  ErrorToast,
  InfoToast,
  LoadingToast,
  SuccessToast,
  WarningToast
} from '@/components/Toasts';

type toastArgs = {
  type: 'error' | 'info' | 'loading' | 'success' | 'warning';
  message: string;
  options?: ToastOptions;
};

const toast = ({ type, message, options: additionalOptions }: toastArgs) => {
  const options = {
    position: reactToastify.POSITION.TOP_RIGHT,
    hideProgressBar: true,
    closeButton: false,
    draggable: false,
    ...additionalOptions
  };

  switch (type) {
    case 'error':
      return reactToastify(<ErrorToast message={message} />, options);
    case 'info':
      return reactToastify(<InfoToast message={message} />, options);
    case 'loading':
      return reactToastify(<LoadingToast message={message} />, options);
    case 'success':
      return reactToastify(<SuccessToast message={message} />, options);
    case 'warning':
      return reactToastify(<WarningToast message={message} />, options);
    default:
      return reactToastify(<ErrorToast message={message} />, options);
  }
};

export default toast;
