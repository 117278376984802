import i18n, { t as translate, use } from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './en.json';

use(initReactI18next).init({
  lng: 'en',
  fallbackLng: 'en',
  resources: {
    en: { translation: en }
  },
  interpolation: { escapeValue: false }
});

export default i18n;

export const t = (key?: string | string[]) => translate(key ?? '');
