import Link from '@/components/Link';

const NavHeader = () => (
  <Link
    className="-translate-y-[1px] px-6 pt-6 text-center text-xl text-white"
    redirectTo="/"
  >
    <span>splash</span>
    <span className="font-bold">brands</span>
  </Link>
);

export default NavHeader;
