import { FaExternalLinkAlt } from 'react-icons/fa';

import Link from '@/components/Link';
import { useFeatureFlags } from '@/hooks';

const DeveloperOptions = () => {
  const { features } = useFeatureFlags();

  const sandbox = window.location.hostname === 'app.splashbrandsdev.com';
  const staging = window.location.hostname === 'app.splashbrandstest.com';
  const production = window.location.hostname === 'app.splashbrands.com';

  return (
    features?.developerMode && (
      <>
        {!sandbox && (
          <Link
            className="flex items-center gap-2 px-4 py-2 hover:bg-background"
            redirectTo="https://app.splashbrandsdev.com"
          >
            <FaExternalLinkAlt />
            <span className="text-sm font-semibold text-secondary">
              Go To Sandbox
            </span>
          </Link>
        )}
        {!staging && (
          <Link
            className="flex items-center gap-2 px-4 py-2 hover:bg-background"
            redirectTo="https://app.splashbrandstest.com"
          >
            <FaExternalLinkAlt />
            <span className="text-sm font-semibold text-secondary">
              Go To Staging
            </span>
          </Link>
        )}
        {!production && (
          <Link
            className="flex items-center gap-2 px-4 py-2 hover:bg-background"
            redirectTo="https://app.splashbrands.com"
          >
            <FaExternalLinkAlt />
            <span className="text-sm font-semibold text-secondary">
              Go To Production
            </span>
          </Link>
        )}
      </>
    )
  );
};

export default DeveloperOptions;
