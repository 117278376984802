import { useRef, useState } from 'react';
import { useController } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';
import type { SelectInstance } from 'react-select';
import { AsyncPaginate } from 'react-select-async-paginate';
import { twMerge } from 'tailwind-merge';

import Message from '@/components/Message';
import Skeleton from '@/components/Skeleton';
import { camelToSnakeCase } from '@/utils/caseConverter';

import Label from '../Label';
import Control from './components/Control';
import MultiValueContainer from './components/MultiValueContainer';
import Option from './components/Option';
import type { SelectProps } from './types';
import { loadEmptyOptions } from './utils/loadEmptyOptions';
import { loadOptions } from './utils/loadOptions';

const Select = ({
  cacheable = true,
  className,
  clearable = true,
  control,
  debounceTimeout = 500,
  disabled,
  endpointVersion,
  formatValue,
  includeParams,
  info,
  label,
  loading = false,
  loadOptionsOnMount = true,
  localSearch = false,
  mapper,
  mapperValueAccessor,
  menuListClassName,
  meta,
  minTermSearchLength = 0,
  multi = false,
  name,
  options,
  optionsUrl,
  placeholder = '',
  refresh,
  required,
  searchable = true,
  searchParamName = 'term',
  showAllOption = false,
  showAllOptionLabel = 'All',
  sorter
}: SelectProps) => {
  const [searchParams] = useSearchParams();
  const [cacheRefresh, setCacheRefresh] = useState(0);
  const [canLoadOptions, setCanLoadOptions] = useState(!minTermSearchLength);

  const selectRef = useRef<SelectInstance>(null);

  const snakeCaseName = camelToSnakeCase(name) as string;
  disabled = disabled ?? meta?.readonlyFields?.includes(snakeCaseName) ?? false;
  required = required ?? meta?.requiredFields?.includes(snakeCaseName) ?? false;

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
    formState: { defaultValues }
  } = useController({
    control,
    name,
    rules: { required: { value: required, message: 'Field required' } }
  });

  return (
    <div
      className={twMerge(
        'relative grid items-center gap-x-6',
        label && 'grid-cols-[20%,calc(80%-1.5rem)]',
        className
      )}
    >
      <Label htmlFor={name} required={required}>
        {label}
      </Label>
      <Skeleton className={loading ? '' : 'hidden'} component="input" />
      <div className={twMerge('relative', loading && 'hidden')}>
        <AsyncPaginate
          backspaceRemovesValue={false}
          cacheUniqs={[
            optionsUrl,
            options,
            defaultValues?.[name],
            !cacheable && cacheRefresh,
            refresh,
            disabled
          ]}
          classNames={{
            container: () => 'w-full',
            control: () =>
              twMerge(
                'shadow-none cursor-text outline-none border-lines',
                error && 'border-errorLines',
                disabled && 'bg-disabled'
              ),
            singleValue: () => twMerge(disabled && 'text-backgroundDisabled'),
            menu: () => 'shadow border border-lines py-1.5 my-0.5 z-30',
            menuList: () =>
              twMerge('mr-1.5 scrollbar-default max-h-96', menuListClassName),
            dropdownIndicator: () =>
              twMerge('cursor-pointer', !disabled && 'text-backgroundDisabled'),
            clearIndicator: () => 'cursor-pointer',
            option: ({ isSelected, isFocused, isMulti }) =>
              twMerge(
                'cursor-pointer text-dropdownItem py-1 text-sm flex items-center',
                isSelected &&
                  !isMulti &&
                  'bg-accent text-white hover:bg-accent',
                isSelected && isMulti && 'bg-white',
                isFocused && !isSelected && 'text-black bg-dropdownHover'
              ),
            valueContainer: () => `pr-0`,
            multiValue: () => 'bg-background rounded-full px-1'
          }}
          closeMenuOnSelect={!multi}
          components={{
            IndicatorSeparator: null,
            Option,
            Control,
            MultiValueContainer
          }}
          debounceTimeout={canLoadOptions ? debounceTimeout : 0}
          defaultOptions={loadOptionsOnMount || !!minTermSearchLength}
          filterOption={
            localSearch
              ? (
                  candidate: { label: string; value: string; data: any },
                  input: string
                ) => {
                  return input
                    ? candidate.label
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    : true;
                }
              : undefined
          }
          hideSelectedOptions={false}
          inputId={name}
          isClearable={clearable}
          isDisabled={disabled}
          isMulti={multi}
          isSearchable={searchable}
          loadOptions={
            canLoadOptions && !disabled
              ? loadOptions(
                  formatValue,
                  onChange,
                  searchParamName,
                  searchParams,
                  value,
                  name,
                  options,
                  optionsUrl,
                  mapper,
                  mapperValueAccessor,
                  sorter,
                  endpointVersion,
                  showAllOption,
                  showAllOptionLabel,
                  includeParams,
                  localSearch
                )
              : loadEmptyOptions()
          }
          loadOptionsOnMenuOpen={!loadOptionsOnMount}
          name={name}
          onBlur={onBlur}
          onChange={selectedOption => {
            if (Array.isArray(selectedOption)) {
              const allOptionSelected = selectedOption.some(
                option => option.value === '_ALL'
              );

              onChange(allOptionSelected ? [] : selectedOption);
            } else {
              onChange(
                (selectedOption as { value: any })?.value === '_ALL'
                  ? null
                  : selectedOption
              );
            }
          }}
          onFocus={() => !cacheable && setCacheRefresh(cache => cache + 1)}
          {...(minTermSearchLength && {
            onInputChange: newValue =>
              setCanLoadOptions(newValue.length >= minTermSearchLength)
          })}
          noOptionsMessage={({ inputValue }) =>
            inputValue.length < minTermSearchLength
              ? `Input value must be longer than ${minTermSearchLength} chars (${
                  minTermSearchLength - inputValue.length
                } left)`
              : 'No options'
          }
          onMenuClose={() => selectRef.current?.blur()}
          placeholder={placeholder}
          selectRef={selectRef}
          tabSelectsValue
          value={value}
        />
        <Message error={!!error}>{error?.message || info}</Message>
      </div>
    </div>
  );
};

export default Select;
