import { RAILS_URL } from '@/environment';

import server from '../server';
import { mapFeatureFlags } from './mappers';
import type { FetchedFeatureFlagData } from './types';

const featureFlagsService = {
  getFeatures: async (userId?: number) => {
    try {
      const data: FetchedFeatureFlagData = await server.get({
        baseURL: RAILS_URL,
        path: 'flipper/api/features'
      });

      return mapFeatureFlags(data, userId);
    } catch (_) {
      return mapFeatureFlags({ features: [] });
    }
  }
};

export default featureFlagsService;
