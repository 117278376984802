import { useState } from 'react';

import Svg from '@/components/Svg';

import type { CategoryHeaderProps } from '../nav.types';

const CategoryHeader = ({
  depth,
  collapsed,
  name,
  toggleCollapsed
}: CategoryHeaderProps) => {
  const [hovered, setHovered] = useState(false);

  const collapsible = depth === 1;

  return collapsible ? (
    <button
      className="group flex h-[43.53px] w-full -translate-x-1 items-center justify-between py-2.5 pl-12 pr-6 pt-[9px]"
      onClick={toggleCollapsed}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <span className="text-[15px] text-tertiary transition-colors duration-300 ease-out group-hover:text-linkHover">
        {name}
      </span>
      <Svg
        hoverStroke="#BCCEE4"
        icon="arrowUp"
        isHovered={hovered}
        rotate={collapsed}
      />
    </button>
  ) : (
    <p
      className={`translate-x-1.5 translate-y-1.5 px-6 py-4 text-tertiary ${
        depth === 0 ? 'text-2xs font-bold uppercase tracking-wider' : ''
      }`}
    >
      {name}
    </p>
  );
};

export default CategoryHeader;
