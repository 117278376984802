import type {
  CategoryContent,
  CategoryItemContent,
  SupercategoryContent
} from '@/components/Nav';
import type { PreformattedAccess } from '@/types/Access';

export const transformAccessesToNavItems = (
  accesses: PreformattedAccess[] = []
) => {
  const sortedAccesses = accesses.filter(
    ({ accessLevel }) => accessLevel !== null
  );

  const output: CategoryContent[] = [];

  for (const {
    path,
    subpaths,
    key,
    section: { key: sectionKey },
    supercategory: { key: supercategoryKey },
    accessLevel
  } of sortedAccesses) {
    const sectionIndex = output.findIndex(
      sectionItem => sectionItem.key === sectionKey
    );

    if (sectionIndex === -1) {
      if (supercategoryKey) {
        output.push({
          key: sectionKey,
          items: [
            {
              key: supercategoryKey,
              items: [{ key, path: `/${path}`, subpaths, accessLevel }]
            }
          ]
        });
      } else {
        output.push({
          key: sectionKey,
          items: [{ key, path: `/${path}`, subpaths, accessLevel }]
        });
      }
    } else {
      const sectionItem = output[sectionIndex];

      if (supercategoryKey) {
        const supercategoryIndex = sectionItem.items.findIndex(
          supercategoryItem => supercategoryItem.key === supercategoryKey
        );

        if (supercategoryIndex === -1) {
          sectionItem.items.push({
            key: supercategoryKey,
            items: [{ key, path: `/${path}`, subpaths, accessLevel }]
          });
        } else {
          (
            sectionItem.items[supercategoryIndex] as SupercategoryContent
          ).items.push({ key, path: `/${path}`, subpaths, accessLevel });
        }
      } else {
        sectionItem.items.push({
          key,
          path: `/${path}`,
          subpaths,
          accessLevel
        });
      }
    }
  }

  return output;
};

export const isCategory = (
  item: CategoryItemContent | CategoryContent
): item is CategoryContent => {
  return 'items' in item;
};
