import { twMerge } from 'tailwind-merge';

import type { BackdropProps } from './backdrop.types';

const Backdrop = ({
  background,
  onClick,
  onMouseEnter,
  zIndex = 40
}: BackdropProps) => (
  <span
    className={twMerge(
      'fixed inset-0',
      background && 'bg-backgroundDisabled/50'
    )}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    style={{ zIndex }}
  />
);

export default Backdrop;
