import useSWR from 'swr';

import authService from '@/services/auth';

const useAuth = () => {
  const { data, error, isLoading, mutate } = useSWR('auth', () =>
    authService.getUser()
  );

  return {
    error,
    isLoading,
    mutate,
    user: data?.data
  };
};

export default useAuth;
