import type { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

type LabelProps = {
  children?: ReactNode;
  htmlFor: string;
  required?: boolean;
};

const Label = ({ children, htmlFor, required }: LabelProps) =>
  children ? (
    <label
      className={twMerge(
        'py-2 text-secondary',
        children === undefined && 'hidden'
      )}
      htmlFor={htmlFor}
    >
      {children}
      {required && ' *'}
    </label>
  ) : null;

export default Label;
