import { twMerge } from 'tailwind-merge';

import type { MessageProps } from './types';

const Message = ({ children, error = false, className }: MessageProps) => (
  <p
    className={twMerge(
      'absolute -bottom-4 col-start-2 px-1 text-xs text-secondary first-letter:capitalize',
      error && 'overflow-hidden whitespace-nowrap text-error',
      !children && 'hidden',
      className
    )}
  >
    {children}
  </p>
);

export default Message;
