import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

import server from '@/services/server';

const useStopImpersonate = () => {
  const navigate = useNavigate();

  const stopImpersonate = async ({ path }: { path: string }) => {
    await server.delete({ path, version: 1 });

    navigate(0);
  };

  const { trigger } = useSWRMutation(
    { path: 'impersonations', method: 'DELETE' },
    stopImpersonate
  );

  return { stopImpersonate: trigger };
};

export default useStopImpersonate;
