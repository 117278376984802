import useSWRImmutable from 'swr/immutable';

import featureFlagsService from '@/services/featureFlags';

import useAuth from './useAuth';

const useFeatureFlags = () => {
  const { user } = useAuth();
  const { data, error, isLoading } = useSWRImmutable('featureFlags', () =>
    featureFlagsService.getFeatures(user?.id)
  );

  return { features: data, error, isLoading };
};

export default useFeatureFlags;
