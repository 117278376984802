import { Link as RouterLink } from 'react-router-dom';

import { RAILS_URL } from '@/environment';
import routes from '@/routes';

import type { LinkProps } from './link.types';

const Link = ({
  children,
  className,
  redirectTo,
  relative = false
}: LinkProps) => {
  if (relative) {
    return (
      <RouterLink className={className} relative="path" to={redirectTo}>
        {children}
      </RouterLink>
    );
  }

  const checkPath = (redirectTo: string) => {
    const parts = redirectTo.split('/');
    return `/${parts[1]}/${parts[2]}`;
  };

  return Object.values(routes).includes(checkPath(redirectTo)) ? (
    <RouterLink
      className={className}
      relative={relative ? 'path' : 'route'}
      to={redirectTo}
    >
      {children}
    </RouterLink>
  ) : (
    <a className={className} href={new URL(redirectTo, RAILS_URL).href}>
      {children}
    </a>
  );
};

export default Link;
