import '@/styles/index.css';
import '@/translations/i18n';
import 'react-toastify/dist/ReactToastify.css';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-datepicker/dist/react-datepicker.css';

import { Provider as NiceModalProvider } from '@ebay/nice-modal-react';
import { browserTracingIntegration, init as initSentry } from '@sentry/react';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  MOCKS_ENABLED,
  SENTRY_DSN,
  SENTRY_ENABLED,
  SENTRY_ENVIRONMENT
} from '@/environment';
import { TableProvider } from '@/providers';

import App from './App.tsx';

if (SENTRY_ENABLED) {
  initSentry({
    dsn: SENTRY_DSN,
    integrations: [browserTracingIntegration()],
    tracesSampleRate: 0.2,
    environment: SENTRY_ENVIRONMENT
  });
}

if (MOCKS_ENABLED) {
  const startWorker = async () => {
    const { worker } = await import('./__mocks__/browser.ts');
    worker.start({ onUnhandledRequest: 'bypass', quiet: true });
  };

  startWorker();
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Router>
      <TableProvider>
        <ToastContainer />
        <NiceModalProvider>
          <App />
        </NiceModalProvider>
      </TableProvider>
    </Router>
  </StrictMode>
);
